import React from 'react';
import classNames from 'classnames';
import {LangLink} from "../LangLink";
import {Button} from "../Button";
import {TypographyV2} from "../TypographyV2";

const styles = require('./SubserviceLink.module.scss');

export function SubserviceLink({className, title, description, slug, icon, hasContent}) {
    const content = <article className={styles.wrapper}>
        {icon && <img className={styles.icon} src={icon} alt={title}/>}
        <TypographyV2 tag={"h3"} variant={"h5"} className={styles.title}>{title}</TypographyV2>
        <TypographyV2 className={styles.description}>{description}</TypographyV2>

        {hasContent && <div className={styles.moreButtonsWrapper}>
            <span className={styles.moreText}>More</span>
            <Button className={styles.moreButton} size="small"
                    component="button"
                    noHover>Go to service</Button>
        </div>}
    </article>;

    if (hasContent) {
        return <LangLink to={`/service/${slug}`} className={classNames(styles.root, className, styles.isLink)}>
            {content}
        </LangLink>;
    }

    return <div className={classNames(styles.root, className)}>
        {content}
    </div>;
}
