import {Layout} from "../components/Layout";
import React from 'react';
import {graphql} from "gatsby";
import {Breadcrumbs} from "../components/Breadcrumbs";
import {Sections} from "../components/Sections";
import {SectionHero} from "../components/SectionHero";
import {useIntl} from "gatsby-plugin-react-intl";
import {ServiceV2SubservicesBlock} from "../components/ServiceV2SubservicesBlock";

export const query = graphql`
    query ServiceV3($locale: String!, $slug: String!) {
        service: strapiServiceV3(slug: {eq: $slug}, locale: {eq: $locale}) {
            name
            subservicesTitle
            sectionHero {
                ...SectionHero
            }
            subservices {
                name
                slug
                shortDescription
                content {
                    __typename
                }
                icon {
                    localFile {
                        url
                    }
                }
            }
            content {
                ...AllSectionsContent
            }
            seo {
                ...Seo
            }
        }
    }
`

export default function ServiceV3Page({data, pageContext}) {
    const intl = useIntl();

    const subservices = data.service.subservices ?? [];

    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={data.service?.name}
                   jobsCount={pageContext.jobsCount}
                   companyData={pageContext.companyData}
                   lang={pageContext.lang}>

        {data.service.sectionHero && <SectionHero title={data.service.sectionHero.title}
                                                  name={data.service.name}
                                                  content={data.service.sectionHero.content.data}
                                                  image={data.service.sectionHero.image?.localFile}
                                                  ctaButtonRef={data.service.sectionHero.ctaButtonRef}
                                                  video={data.service.sectionHero.video}
                                                  breadcrumbs={
                                                      <Breadcrumbs>
                                                          <Breadcrumbs.Item
                                                              to="/">{intl.formatMessage({id: 'menu.home'})}</Breadcrumbs.Item>
                                                          <Breadcrumbs.Item
                                                              isLast={true}>{data.service.name}</Breadcrumbs.Item>
                                                      </Breadcrumbs>
                                                  }
        />}
        {subservices.length > 0 && <ServiceV2SubservicesBlock
            title={data.service.subservicesTitle}
            subservices={
                subservices.map(x => {
                    return {
                        title: x.name,
                        description: x.shortDescription,
                        icon: x.icon?.localFile?.url,
                        slug: x.slug,
                        hasContent: x.content?.length > 0
                    }
                })
            }
        />}
        <Sections
            sections={data.service.content}
        />
    </Layout>
}
